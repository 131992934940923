
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class MentionItem extends Vue {
    @Prop({ required: true }) items!: Array<{ id: number; label: string }>;
    @Prop({ required: true }) command!: Function;

    selectedIndex = 0;

    @Watch("items")
    handleItemsChanged() {
        this.selectedIndex = 0;
    }

    onKeyDown({ event }: { event: KeyboardEvent }) {
        if (event.key === "ArrowUp") {
            this.upHandler();
            return true;
        }

        if (event.key === "ArrowDown") {
            this.downHandler();
            return true;
        }

        if (event.key === "Enter") {
            this.enterHandler();
            return true;
        }

        return false;
    }

    upHandler() {
        this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
    }

    downHandler() {
        this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    }

    enterHandler() {
        this.selectItem(this.selectedIndex);
    }

    selectItem(index: number) {
        const item = this.items[index];

        if (item) {
            this.command(item);
        }
    }
}
