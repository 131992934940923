// @ts-nocheck
import { VueRenderer } from "@tiptap/vue-2";
import tippy from "tippy.js";
import type Tippy from "tippy.js";
import { Action, Getter } from "vuex-class";
import MentionList from "@/components/mentions/mention-item.vue";
import timelineStore from '@/store/modules/timeline.store';
import partnerRequestStore from '@/store/modules/partner-request.store';

export default {
    items: async ({ query }: { query: string }) => {
   
        const accounts = partnerRequestStore.state.viewing.activity.assigned_to.map((a) => {
            return { id: a.id, label: `${a.first_name} ${a.last_name}` };
        });

        return accounts.filter((item) => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
    },

    render: () => {
        let component: VueRenderer;
        let popup: typeof Tippy;

        return {
            onStart: (props) => {
                component = new VueRenderer(MentionList, {
                    parent: this,
                    propsData: props,
                    editor: props.editor,
                });

                if (!props.clientRect) {
                    return;
                }

                popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === "Escape") {
                    popup[0].hide();

                    return true;
                }

                return component.ref?.onKeyDown(props);
            },

            onExit() {
                popup[0].destroy();
                component.destroy();
            },
        };
    },
};
